import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MyTeamCard = _resolveComponent("MyTeamCard")

  return (_openBlock(), _createElementBlock("div", null, [
    ($options.isValidProvider)
      ? (_openBlock(), _createBlock(_component_MyTeamCard, {
          key: 0,
          contact: $props.providerRep,
          size: $props.size,
          "analytics-data": $props.analyticsData ? $props.analyticsData : {},
          "hide-texting": false,
          "data-id": "provider-rep"
        }, null, 8, ["contact", "size", "analytics-data"]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.contacts, (contact, index) => {
      return (_openBlock(), _createBlock(_component_MyTeamCard, {
        key: index,
        contact: contact,
        size: $props.size,
        "analytics-data": $props.analyticsData ? $props.analyticsData : {}
      }, null, 8, ["contact", "size", "analytics-data"]))
    }), 128))
  ]))
}