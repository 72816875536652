
import PDEIconButton from '@/components/pde/PDEIconButton.vue'

export default {
  name: 'PDEDrawer',

  components: { PDEIconButton },

  props: {
    position: {
      type: String,
      default: 'right',
      validator: (value) =>
        ['right', 'left', 'top', 'bottom'].indexOf(value) !== -1,
    },

    width: {
      type: String,
      default: 'fixed',
      validator: (value) => ['fixed', 'full'].indexOf(value) !== -1,
    },

    height: {
      type: String,
      default: 'fixed',
      validator: (value) => ['fixed', 'full'].indexOf(value) !== -1,
    },

    title: {
      type: String,
      default: '',
    },

    display: {
      type: Boolean,
      default: false,
    },

    fixed: {
      type: Boolean,
      default: false,
    },

    persistent: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['drawer:opened', 'drawer:closed', 'drawer-scrim-click'],

  data() {
    return {
      hideScrim: 'opacity-0 pointer-events-none',
      showScrim: 'opacity-30 pointer-events-auto',
      scrimClasses: '',
      drawerClasses: '',
      animate: 'transition-all duration-500 ease-in-out',
    }
  },

  computed: {
    positionClasses() {
      const positions = {
        right: 'top-0 right-0',
        left: 'top-0 left-0',
        top: 'top-0',
        bottom: 'bottom-0',
      }

      return positions[this.position]
    },

    sizeClasses() {
      const widthSize = this.width === 'fixed' ? 'max-w-352' : ''
      const heightSize = this.height === 'fixed' ? 'max-h-336' : 'h-full'

      return `${widthSize} ${heightSize}`
    },
    headerClasses() {
      const show = 'p-16 tw border-solid border-pureblack-10 border-b-1 border-0'
      const hide = 'pt-16 px-16 border-0'
      return this.title ? show : hide
    },
    showDrawer() {
      const classes = {
        right: 'translate-x-0 opacity-100 visible',
        left: 'translate-x-0 opacity-100 visible',
        top: 'translate-y-0 opacity-100 visible',
        bottom: 'translate-y-0 opacity-100 visible',
      }

      return classes[this.position]
    },

    hideDrawer() {
      const classes = {
        right: 'translate-x-full opacity-0 invisible',
        left: '-translate-x-full opacity-0 invisible',
        top: '-translate-y-full opacity-0 invisible',
        bottom: 'translate-y-full opacity-0 invisible',
      }

      return classes[this.position]
    },
  },

  watch: {
    display(value) {
      if (value) {
        this.openDrawer()
      } else {
        this.closeDrawer()
      }
    },
  },

  mounted() {
    this.scrimClasses = this.display ? this.showScrim : this.hideScrim
    this.drawerClasses = this.display ? this.showDrawer : this.hideDrawer
  },

  methods: {
    openDrawer() {
      this.$emit('drawer:opened')
      this.scrimClasses = `${this.animate} ${this.showScrim}`
      this.drawerClasses = `${this.animate} ${this.showDrawer}`
    },

    closeDrawer() {
      this.$emit('drawer:closed')
      this.scrimClasses = `${this.animate} ${this.hideScrim}`
      this.drawerClasses = `${this.animate} ${this.hideDrawer}`
    },

    scrimClick() {
      if (this.persistent) return

      this.$emit('drawer-scrim-click')
      this.closeDrawer()
    },
  },
}
