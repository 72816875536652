
import PDESkeleton from '@/components/pde/PDESkeleton.vue'
import PDEContactCard from '@/components/pde/PDEContactCard.vue'
import { mapActions } from 'vuex'

export default {
  name: 'MyTeamCard',

  components: {
    PDEContactCard,
    PDESkeleton,
  },

  props: {
    contact: {
      type: Object,
      default: () => undefined,
    },

    analyticsData: {
      type: Object,
      default: () => {},
    },

    hideTexting: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    hasContactData() {
      return Object.keys(this.contact).length > 0
    },

    getAnalyticClickAreaName() {
      return this.analyticsData &&
        this.analyticsData.areaType === 'nav'
        ? 'my-team'
        : `my-${this.$t('global.brandTitle')}-team`
    },

    getAnalyticsAreaType() {
      return this.analyticsData && this.analyticsData.areaType
        ? this.analyticsData.areaType
        : 'nav'
    },
  },

  methods: {
    ...mapActions('account', ['downloadVcard']),
    createVcard() {
      this.downloadVcard(this.contact)
    },
  },
}
