import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]
const _hoisted_3 = {
  "data-id": "pde-accordion__title",
  class: "flex items-center text-12 font-500 w-11/12"
}
const _hoisted_4 = ["id", "aria-expanded", "aria-controls", "aria-disabled", "disabled"]
const _hoisted_5 = ["id", "aria-labelledby", "aria-hidden"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    id: $props.id,
    "data-id": "pde-accordion",
    class: "box-border"
  }, [
    _createElementVNode("div", {
      id: $props.accordionHeaderId,
      class: _normalizeClass($props.headerClasses),
      "data-id": "pde-accordion__header"
    }, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "header")
      ]),
      _withDirectives(_createElementVNode("button", {
        id: $props.accordionButtonId,
        class: _normalizeClass([[$options.getAccordionButtonIcons, $props.btnClasses], "right-0 bg-transparent border-0 text-pureblack-60 pde-icon cursor-pointer text-21 font-800 p-0"]),
        "aria-expanded": $props.isExpandable && $data.open,
        "aria-controls": $props.accordionContentId,
        "aria-disabled": !$props.isExpandable,
        disabled: !$props.isExpandable,
        "data-id": "pde-accordion__button",
        "aria-label": "accordion toggle button",
        type: "button",
        onClick: _cache[0] || (_cache[0] = (...args) => ($options.toggleClick && $options.toggleClick(...args)))
      }, null, 10, _hoisted_4), [
        [_vShow, $props.isExpandable]
      ])
    ], 10, _hoisted_2),
    _createElementVNode("div", {
      id: $props.accordionContentId,
      "aria-labelledby": $props.accordionButtonId,
      "aria-hidden": $props.isExpandable && !$data.open,
      style: _normalizeStyle({height: $data.open || !$props.isExpandable ? 'auto': '0', opacity: $data.open || !$props.isExpandable ? 1 : 0}),
      class: _normalizeClass([$props.contentBg, { 'overflow-hidden': $props.isOverflowHidden || !$data.open }, 'transition-height duration-300 ease-in-out']),
      "data-id": "pde-accordion__content",
      role: "region"
    }, [
      _renderSlot(_ctx.$slots, "content")
    ], 14, _hoisted_5)
  ], 8, _hoisted_1))
}