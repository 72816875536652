
import PDEDropDown from '@/components/pde/PDEDropDown.vue'
import PDEIcon from '@/components/pde/PDEIcon.vue'
import PDEHeading from '@/components/pde/PDEHeading.vue'

export default {
  name: 'PDEContactCard',

  components: {
    PDEHeading,
    PDEIcon,
    PDEDropDown,
  },

  props: {
    name: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      default: '',
    },

    email: {
      type: String,
      default: '',
    },

    phone: {
      type: String,
      default: '',
    },

    phoneRaw: {
      type: String,
      default: '',
    },

    size: {
      type: String,
      default: 'large',
      validator: (value) => ['large', 'small'].indexOf(value) !== -1,
    },

    hideTexting: {
      type: Boolean,
      default: false,
    },

    options: {
      type: Array,
      default: () => [],
    },

    analyticsData: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    nameAndtitleSize() {
      return this.isSmall ? 'mb-4' : 'fit-min'
    },

    infoSize() {
      return this.isSmall ? 'mb-4' : 'fit-max'
    },

    textDisplayClass() {
      return this.isSmall ? 'xs:block' : 'xs:flex'
    },

    itemSize() {
      return this.isSmall ? 'w-full mb-4' : 'xs:max-w-248 xs:truncate'
    },

    isSmall() {
      return this.size === 'small'
    },

    contactSize() {
      return this.isSmall ? 'xs:inline-block' : ''
    },

    menuPlacement() {
      return (this.name === 'Travel' && this.size === 'large') ? 'topRight' : 'bottomRight'
    },
  },
}
